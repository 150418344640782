 import React, { useState, useEffect } from "react";
 import { motion } from "framer-motion";

// const Countdown = () => {
//   const [targetTime, setTargetTime] = useState(() => {
//     const savedTime = localStorage.getItem("targetTime");
//     if (savedTime) {
//       return parseInt(savedTime, 10);
//     }
//     const newTargetTime = new Date().getTime() + 60 * 1000;
//     localStorage.setItem("targetTime", newTargetTime);
//     return newTargetTime;
//   });

//   const [timeLeft, setTimeLeft] = useState(getTimeLeft(targetTime));
//   const [isWeekend, setIsWeekend] = useState(false);

//   useEffect(() => {
//     const checkWeekend = () => {
//       const day = new Date().getDay();
//       setIsWeekend(day === 0 || day === 1 || day === 2 || day === 3 || day === 4 || day === 5 || day === 6); 
//     };

//     checkWeekend();

//     const timer = setInterval(() => {
//       checkWeekend(); // Re-check in case day changes
//       setTimeLeft(getTimeLeft(targetTime));
//     }, 1000);

//     return () => clearInterval(timer);
//   }, [targetTime]);

//   function getTimeLeft(targetTime) {
//     const now = new Date().getTime();
//     const difference = targetTime - now;
//     if (difference <= 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

//     return {
//       days: Math.floor(difference / (1000 * 60 * 60 * 24)),
//       hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
//       minutes: Math.floor((difference / 1000 / 60) % 60),
//       seconds: Math.floor((difference / 1000) % 60),
//     };
//   }

//   const swipeUpAnimation = {
//     initial: { y: 20, opacity: 0 },
//     animate: { y: 0, opacity: 1 },
//     transition: { duration: 0.4, ease: "easeOut" },
//   };

//   if (!isWeekend) return null; // Hide the counter if it's not Saturday or Sunday

//   return (
//     <div className="flex items-start justify-center w-full gap-4 count-down-main">
//       {["days", "hours", "minutes", "seconds"].map((unit) => (
//         <div key={unit} className="timer w-16">
//           <motion.div
//             className="bg-gradient-to-r from-orange-400 via-red-400 to-red-500 py-4 px-2 rounded-lg overflow-hidden"
//             initial="initial"
//             animate="animate"
//             transition={{ duration: 0.4, ease: "easeOut" }}
//           >
//             <motion.h3
//               className="countdown-element font-Cormorant font-semibold text-2xl text-white text-center"
//               key={timeLeft[unit]}
//               {...swipeUpAnimation}
//             >
//               {timeLeft[unit] < 10 ? `0${timeLeft[unit]}` : timeLeft[unit]}
//             </motion.h3>
//           </motion.div>
//           <p className="text-lg font-Cormorant font-medium text-gray-900 mt-1 text-center w-full">
//             {unit}
//           </p>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Countdown;
// import React, { useState, useEffect } from "react";
// import { motion } from "framer-motion";

const Countdown = () => {
  const getNewTargetTime = () => new Date().getTime() + 2*24*60* 60 * 1000;

  const [targetTime, setTargetTime] = useState(() => {
    const savedTime = localStorage.getItem("targetTime");
    if (savedTime) {
      return parseInt(savedTime, 10);
    }
    const newTargetTime = getNewTargetTime();
    localStorage.setItem("targetTime", newTargetTime);
    return newTargetTime;
  });

  const [timeLeft, setTimeLeft] = useState(getTimeLeft(targetTime));

  useEffect(() => {
    const timer = setInterval(() => {
      const updatedTimeLeft = getTimeLeft(targetTime);

      if (updatedTimeLeft.seconds <= 0 && updatedTimeLeft.minutes <= 0 && updatedTimeLeft.hours <= 0 && updatedTimeLeft.days <= 0) {
        const newTarget = getNewTargetTime();
        setTargetTime(newTarget);
        localStorage.setItem("targetTime", newTarget);
      } else {
        setTimeLeft(updatedTimeLeft);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [targetTime]);

  function getTimeLeft(targetTime) {
    const now = new Date().getTime();
    const difference = targetTime - now;
    if (difference <= 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  const swipeUpAnimation = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { duration: 0.4, ease: "easeOut" },
  };

  return (
    <div className="flex items-start justify-center w-full gap-4 count-down-main">
      {["days", "hours", "minutes", "seconds"].map((unit) => (
        <div key={unit} className="timer w-16">
          <motion.div
            className="bg-gradient-to-r from-orange-400 via-red-400 to-red-500 py-4 px-2 rounded-lg overflow-hidden"
            initial="initial"
            animate="animate"
            transition={{ duration: 0.4, ease: "easeOut" }}
          >
            <motion.h3
              className="countdown-element font-Cormorant font-semibold text-2xl text-white text-center"
              key={timeLeft[unit]}
              {...swipeUpAnimation}
            >
              {timeLeft[unit] < 10 ? `0${timeLeft[unit]}` : timeLeft[unit]}
            </motion.h3>
          </motion.div>
          <p className="text-lg font-Cormorant font-medium text-gray-900 mt-1 text-center w-full">
            {unit}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Countdown;

