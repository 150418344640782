import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { motion } from "framer-motion";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";
import background2 from "../assets/1.png";
import background1 from "../assets/2.png";

function Hero() {
  const slides = [
    {
      image: background1,
      title: "Kick start your trading journey with confidence",
      description:
        "Money Doesn't Come Without Guidance, let's Move On with Proficient Advice",
      button: "Book Your Seat Today",
    },
    {
      image: background2,
      title: "Innovative Solutions",
      description: "Transform Your Trading with Our Analysis",
      button: "Get Started",
    },
  ];

  return (
    <section className="relative z-10 ">
      <Swiper
        navigation={{
          nextEl: ".swiper-button-next-custom",
          prevEl: ".swiper-button-prev-custom",
        }}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        modules={[Navigation, Autoplay]}
        className="h-[75vh]"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -50 }}
              transition={{ duration: 0.8 }}
              className="relative h-full text-white overflow-hidden"
            >
              <div className="absolute inset-0">
                <img
                  src={slide.image}
                  alt={`Background ${index + 1}`}
                  className="object-cover object-center w-full h-full brightness-75"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black opacity-30"></div>{" "}
                {/* Enhanced gradient */}
              </div>

              <div className="relative z-10 flex flex-col justify-center items-center h-full text-center px-4 md:px-8">
                <h1 className="text-3xl md:text-4xl lg:text-5xl font-extrabold leading-snug mb-4">
                  {slide.title}
                </h1>
                <p className="text-md md:text-lg lg:text-lg mb-6 text-gray-200">
                  {slide.description}
                </p>
                <a
                  href="##"
                  className="bg-[#2db928] text-gray-100 hover:bg-[#2db928]/70 py-2 px-5 lg:py-2 lg:px-8 rounded-full text-xs md:text-base lg:text-lg font-semibold transition-transform transform hover:scale-105"
                >
                  {slide.button}
                </a>
              </div>
            </motion.div>
          </SwiperSlide>
        ))}

        <div className="swiper-button-prev-custom absolute left-4 top-1/2 transform -translate-y-1/2 p-2  rounded-full text-white cursor-pointer z-20">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="md:w-6 md:h-6 lg:w-8 lg:h-8 xl:w-8 xl:h-8 w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </div>
        <div className="swiper-button-next-custom absolute right-4 top-1/2 transform -translate-y-1/2 p-2  rounded-full text-white cursor-pointer z-20">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="md:w-6 md:h-6 lg:w-8 lg:h-8 xl:w-8 xl:h-8 w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      </Swiper>
    </section>
  );
}

export default Hero;
