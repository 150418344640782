import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import E1 from "../assets/E1.jpg";
import E2 from "../assets/E2.jpg";
import stock from "../assets/Stocks4K.gif";
import e3 from "../assets/e3.png";
import E4 from "../assets/E4.jpg";
import e5 from "../assets/e5.jpg";
import e6 from "../assets/e6.jpg";
import e7 from "../assets/e7.jpg";
import e8 from "../assets/e8.jpg";
import e9 from "../assets/e9.jpg";

const FeedBack = () => {
  const feedback = [E1, E2, e3, E4, e5, e6, e7, e8, e9];
  const [isMobile, setIsMobile] = useState(false);
  const [visibleCount, setVisibleCount] = useState(8); // Initial count of images

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const handleToggleVisibility = () => {
    setVisibleCount((prevCount) => (prevCount === 8 ? feedback.length : 8)); // Toggle between 8 and all images
  };

  return (
    <div className="relative bg-gray-100 py-8">
      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-800">
          What They Say About <span className="text-green-500">Us</span>
        </h1>
        <p className="text-gray-600 mt-2">
          We express our gratitude to our clients for their positive feedback.
        </p>
      </div>

      {isMobile ? (
        <Swiper
          modules={[Pagination, Navigation]}
          pagination={{ clickable: true }}
          navigation
          spaceBetween={10}
          slidesPerView={1}
          loop={true}
        >
          {feedback.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="p-4">
                <img
                  src={image}
                  alt={`Feedback ${index + 1}`}
                  className="w-full h-72 object-contain rounded-lg shadow-lg"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 px-6">
          {feedback.slice(0, visibleCount).map((image, index) => (
            <div key={index} className="p-4">
              <img
                src={image}
                alt={`Feedback ${index + 1}`}
                className="w-full h-72 object-cover rounded-lg shadow-lg hover:scale-105 transition-transform duration-300"
              />
            </div>
          ))}
        </div>
      )}

      <div className="text-center mt-6">
        <button
          onClick={handleToggleVisibility}
          className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600 transition-colors duration-300"
        >
          {visibleCount === 8 ? "See More" : "See Less"}
        </button>
      </div>
    </div>
  );
};

export default FeedBack;
