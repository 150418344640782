import React from "react";

const Warning = () => {
  return (
    <div className="w-full bg-[#2db928] overflow-hidden h-auto whitespace-nowrap  py-1 items-center">
      {" "}
      {/* Add margin-top here */}
      <p className='inline-block animate-scroll after:content-["  "] leading-3 delay-1000 text-gray-100 items-center mt-4'>
        Investment/Trading in Securities Market is subject to market risk. Read
        all the related documents carefully before investing. Please read the
        document of our privacy policy, terms of use & disclaimer on our
        website. We do not provide any guaranteed or assured return services,
        profit sharing services, Demat Account services, Investment related
        services or any other services which are not mentioned on our website.
      </p>
    </div>
  );
};

export default Warning;
