import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const PopUp = ({ imageSrc, redirectUrl }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible(true);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const closePopup = () => setIsVisible(false);

  const handleImageClick = () => {
    window.location.href = redirectUrl;
  };

  return isVisible ? (
    <motion.div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="relative bg-white rounded-lg"
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.8 }}
        transition={{ type: "spring", stiffness: 200, damping: 20 }}
      >
        <button
          className="absolute text-white top-2 right-2"
          onClick={closePopup}
          aria-label="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="bg-red-500 w-8 h-8 p-1 rounded-full shadow hover:bg-red-600"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div
          className="cursor-pointer flex justify-center items-center"
          onClick={handleImageClick}
        >
          <img
            src={imageSrc}
            alt="Popup"
            className="w-full max-w-5xl h-auto object-contain"
          />
        </div>
      </motion.div>
    </motion.div>
  ) : null;
};

export default PopUp;
