import React from "react";
import ReachUs from "../components/ReachUs";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import Warning from "../components/Warning";
import Banner from "../components/Banner";

const ContactUs = () => {
  return (
    <>
      <Banner />

      <section className="hidden lg:block">
        <Navbar />
        <Warning />
      </section>
      <section className="block lg:hidden">
        <Sidebar />
      </section>
      <ReachUs />
      <Footer />
    </>
  );
};

export default ContactUs;
