// import Hero from "../components/Hero";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import WhatWeOffer from "../components/HomePageComp/WhatWeOffer";
import Warning from "../components/Warning";
import GetToKnow from "../components/GetToKnow";
import image1 from "../assets/howwork.png";
import ContactButton from "../components/ContactButton";
import Hero from "../components/Hero";
import Countdown from "../components/Countdown";
import PopUp from "../components/PopUp";
import Banner from "../components/Banner";
import NotificationPopup from "../components/NotificationPopup";
import FeedBack from "../components/FeedBack";

function Homepage() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const day = new Date().getDay();
  return (
    // <Profiler id="MyComponent" onRender={onRenderCallback}>
    //   <KYC/>
    // </Profiler>
    <section className="relative">
      <Banner />

      <section className="hidden lg:block ">
        <Navbar />
        <Warning />
      </section>

      <section className="block lg:hidden">
        <Sidebar />
      </section>

      <PopUp
        imageSrc="/popupimg.png"
        redirectUrl="https://www.equityresearchmart.in/equity-process"
      />

      <section className="h-auto md:h-[80vh] relative bg-blue-800">
        <Hero />
      </section>
      <section className="w-full min-h-screen px-4 py-10 bg-white flex flex-col items-center justify-center space-y-6 sm:space-y-8 md:space-y-10">
        {/* {day === 0 || day === 1 || day === 2 || day === 3 || day === 4 || day === 5 || day === 6 &&()} */}
        <div className="">
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold text-center text-slate-800 tracking-[1px]">
            💸 Special <span className="text-[#2db928]"> Offer!</span>
            <br /> Grab Your{" "}
            <span className="text-[#2db928]">Exclusive Deals</span> Now! 💥
          </h1>

          <h1 className="text-xl sm:text-2xl md:text-2xl lg:text-3xl font-extrabold text-center text-slate-800 tracking-[1px]">
            Get 20% Off on all our Services
          </h1>

          <Countdown />
        </div>

        <Link to="/equity-process">
          <button className="px-5 py-3 text-base sm:text-lg font-semibold text-white bg-[#2db928] rounded-full shadow-lg hover:bg-blue-500 hover:shadow-xl transition-all duration-300 ease-in-out">
            Contact Us
          </button>
        </Link>

        <button className="p-2 sm:p-3 text-lg font-semibold text-white animate-bounce rounded-full shadow-lg hover:bg-blue-500 hover:shadow-xl transition-all duration-300 ease-in-out">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 sm:w-8 sm:h-8 text-black"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3"
            />
          </svg>
        </button>
      </section>

      <section className="w-full   flex flex-col items-center ">
        <WhatWeOffer />
      </section>

      <GetToKnow />

      <FeedBack className="h-auto" />

      {/* <div className=" relative bg-gradient-to-br from-[#01295c] via-slate-900 to-[#000000] pt-4 pb-4 border-t-8 border-[#2db928]"> */}
      <div
        className="grid lg:grid-cols-2 items-center justify-items-center gap-5  min-h-screen  relative
       bg-gradient-to-br from-[#01295c] via-slate-900 to-[#000000] pt-4 py-4 border-t-4 border-[#2db928]"
      >
        <div className="order-1 lg:order-1">
          <motion.img
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="h-64 w-64 object-cover lg:w-[400px] lg:h-[400px]"
            src={image1}
            alt="How We Work"
          />
        </div>
        <div className="order-2 lg:order-2 flex flex-col justify-center lg:items-start text-center sm:text-left">
          <h1 className="text-3xl md:text-5xl font-bold text-white">
            How We <span className="text-[#2db928]">Work</span>
          </h1>
          <p className="mt-2 text-md md:text-lg text-gray-200">
            Discover our innovative approach to delivering results.
          </p>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2, when: "beforeChildren" }}
            className="flex justify-center items-center flex-wrap mt-8 space-x-4"
          >
            {Array(5)
              .fill(0)
              .map((_, i) => {
                return (
                  <motion.p
                    initial={{ scale: 0 }}
                    whileInView={{ scale: 1 }}
                    transition={{
                      duration: 0.5,
                      delay: i * 0.4,
                      type: "spring",
                    }}
                    key={i}
                    className="w-12 h-12 bg-[#2db928] text-[#FFFFF] rounded-full text-xl flex items-center justify-center shadow-lg hover:shadow-xl transition duration-300"
                  >
                    {i + 1}
                  </motion.p>
                );
              })}
          </motion.div>
          <p className="mt-6 lg:text-lg md:text-sm font-light text-white text-start mr-5 md:px-0 px-3">
            We categorize our products into three core research views: Technical
            Levels-Based View, Analytical Charts/Signals-Based View, and a
            combined approach that integrates fundamental effects on technical
            resistance and support levels. We have integrated these unique
            techniques into custom software to promptly determine and deliver
            levels efficiently.
          </p>
          <div className="flex justify-center mt-8">
            <Link to={"/equity-our-process"}>
              {" "}
              <button className="text-lg md:text-xl bg-[#2db928] px-4 py-1 text-white text-md font-normal rounded-full">
                Know More
              </button>
            </Link>
          </div>
        </div>
      </div>
      <NotificationPopup />
      <ContactButton />
      <Footer />
    </section>
  );
}

export default Homepage;
