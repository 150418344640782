import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const notifications = [
  "Vimal just enrolled for Trading In The  - ERM Holding Pack 3 mins ago",
  "Priya just enrolled for Trading In The  - ERM WEALTH 5 mins ago",
  "Rohit just enrolled for Trading In The - ERM SWING PACK 7 mins ago",
  "Anjali just enrolled for Trading In The  - ERM Equity & Derivative Combo 10 mins ago",
  "Kunal just enrolled for Trading In The  - ERM Stock OPTION PACK 12 mins ago",
  "Sonia just enrolled for Trading In The  - ERM Index (Future & Option) 15 mins ago",
  "Rahul just enrolled for Trading In The  - ERM COMMODITY (Base Metal & Energy) 18 mins ago",
  "Neha just enrolled for Trading In The  - ERM Full MCX Pack 20 mins ago",
  "Amit just enrolled for Trading In The  - ERM INDEX Option PACK 25 mins ago",
  "Karan just enrolled for Trading In The  - ERM Holding Pack 28 mins ago",
  "Deepika just enrolled for Trading In The  - ERM WEALTH 30 mins ago",
  "Nikhil just enrolled for Trading In The  - ERM SWING PACK 32 mins ago",
  "Meera just enrolled for Trading In The  - ERM Equity & Derivative Combo 35 mins ago",
  "Arjun just enrolled for Trading In The  - ERM Stock OPTION PACK 37 mins ago",
  "Pooja just enrolled for Trading In The  - ERM Index (Future & Option) 40 mins ago",
  "Manish just enrolled for Trading In The  - ERM COMMODITY (Base Metal & Energy) 45 mins ago",
  "Sahil just enrolled for Trading In The  - ERM Full MCX Pack 47 mins ago",
  "Radhika just enrolled for Trading In The  - ERM INDEX Option PACK 50 mins ago",
  "Ravi just enrolled for Trading In The  - ERM Holding Pack 53 mins ago",
  "Simran just enrolled for Trading In The  - ERM WEALTH 55 mins ago",
  "Aditya just enrolled for Trading In The  - ERM SWING PACK 58 mins ago",
  "Ankita just enrolled for Trading In The  - ERM Equity & Derivative Combo 1 hour ago",
  "Ashish just enrolled for Trading In The  - ERM Stock OPTION PACK 1 hour ago",
  "Divya just enrolled for Trading In The  - ERM Index (Future & Option) 1 hour ago",
  "Raj just enrolled for Trading In The  - ERM COMMODITY (Base Metal & Energy) 1 hour ago",
  "Sneha just enrolled for Trading In The  - ERM Full MCX Pack 1 hour ago",
  "Vivek just enrolled for Trading In The  - ERM INDEX Option PACK 1 hour ago",
  "Priyansh just enrolled for Trading In The  - ERM Holding Pack 1 hour ago",
  "Ayesha just enrolled for Trading In The  - ERM WEALTH 1 hour ago",
  "Dev just enrolled for Trading In The  - ERM SWING PACK 1 hour ago",
  "Monika just enrolled for Trading In The  - ERM Equity & Derivative Combo 1 hour ago",
  "Ishaan just enrolled for Trading In The  - ERM Stock OPTION PACK 1 hour ago",
  "Tanya just enrolled for Trading In The  - ERM Index (Future & Option) 1 hour ago",
  "Parth just enrolled for Trading In The  - ERM COMMODITY (Base Metal & Energy) 1 hour ago",
  "Aarav just enrolled for Trading In The  - ERM Full MCX Pack 1 hour ago",
  "Reema just enrolled for Trading In The  - ERM INDEX Option PACK 1 hour ago",
  "Aryan just enrolled for Trading In The  - ERM Holding Pack 1 hour ago",
  "Madhav just enrolled for Trading In The  - ERM WEALTH 1 hour ago",
  "Bhavana just enrolled for Trading In The  - ERM SWING PACK 1 hour ago",
  "Lakshay just enrolled for Trading In The  - ERM Equity & Derivative Combo 1 hour ago",
  "Sana just enrolled for Trading In The  - ERM Stock OPTION PACK 1 hour ago",
  "Kavya just enrolled for Trading In The  - ERM Index (Future & Option) 1 hour ago",
  "Kabir just enrolled for Trading In The  - ERM COMMODITY (Base Metal & Energy) 1 hour ago",
  "Zara just enrolled for Trading In The  - ERM Full MCX Pack 1 hour ago",
  "Ira just enrolled for Trading In The  - ERM INDEX Option PACK 1 hour ago",
  "Tanmay just enrolled for Trading In The  - ERM Holding Pack 1 hour ago",
  "Naina just enrolled for Trading In The  - ERM WEALTH 1 hour ago",
];

const NotificationPopup = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % notifications.length);
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="fixed bottom-5 left-5 hidden md:block w-80 z-40">
      <AnimatePresence mode="wait">
        <motion.div
          key={notifications[index]}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}
          className="bg-gray-200 text-sm shadow-lg rounded-lg p-4 border-l-8 border-blue-500 text-gray-700"
        >
          {notifications[index]}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default NotificationPopup;
