import React from "react";

const Banner = () => {
  return (
    <div className="w-full bg-blue-700 text-white py-1 px-4">
      <div className="max-w-7xl md:text-sm text-[7px] mx-auto flex flex-row md:flex-row justify-between items-center space-y-1 md:space-y-0">
        <div className="flex items-center space-x-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 10l9-9m0 0l9 9M4.5 11h15"
            />
          </svg>
          <span>
            503, A, Patliputra Mall, Block - B, Exhibition Road, Patna
          </span>
        </div>
        <div className="flex items-center space-x-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10l4.94 4.94a2.5 2.5 0 11-3.54 3.54L10 15m5-5l-1.06-1.06a2.5 2.5 0 00-3.54 0L5 10"
            />
          </svg>
          <span>+91 70044 93381</span>
        </div>
      </div>
    </div>
  );
};

export default Banner;
